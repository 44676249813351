<template>
    <section>
        <div class='parent' id='home'>
            <h1>
                <div>מחקר על קורונה <span :style='{ fontSize: "0.7rem" }'>(KMC-0092-20)</span></div>
            </h1>
            <div>
                <div class='youtube'>
                    <iframe
                        src='https://www.youtube.com/embed/seaXKr5_jrU?rel=0'
                        frameborder='0'
                        allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
                        allowfullscreen
                    ></iframe>
                </div>
                <p>
                    הקורונה נמצאת איתנו כבר יותר משנה וגבתה קורבנות רבים.
                </p>
                <p>
                    הבנה טובה יותר של הגורמים שמשפיעים על מהלך המחלה והדברים שסייעו 
                    לחולים להחלים יכולה לסייע במציאת דרכי טיפול טובות יותר.
                </p>
                <p>
                    מחקר השאלונים שלנו מאפשר איסוף נתונים ממספר רב של חולים,
                    וניתוחם על ידי תוכנות מחשב שמאפשרות ניתוח של הרבה נתונים תוך זמן קצר.
                </p>
                <p>
                    כל שנדרש מכם הוא למלא שאלון קצר שאורך כ 10 דקות.
                </p>
                <p :style='{ textAlign: "center", fontWeight: "bold", margin: "0.8rem 0" }'>נודה על השתתפותכם!</p>
                <h3>
                    אם אובחנתם כחולים בקורונה, עם או בלי תסמינים, או אם היו או יש לכם תסמינים שמתאימים לקורונה (גם ללא אבחנה), נשמח אם תשתתפו במחקר שלנו!
                    המחקר מיועד לנבדקים מעל גיל 18 בלבד.
                    לקבלת קישור לשאלון, ניתן לפנות לד"ר מיכל הרן בוואטסאפ
                    <a href='https://coronastudy.co.il/whatsapp' target='_blank' rel='noreferrer'>כאן</a>
                    או במספר {{ $store.state.studyPhone }}.
                </h3>
                <div class='info'>
                    המחקר (KMC-0092-20) קיבל אישור של ועדת הלסינקי המוסדית ונעשה בהתאם לכל הכללים והדרישות של משרד הבריאות.<br/>
                    <a href='https://firebasestorage.googleapis.com/v0/b/corona-study-334515.appspot.com/o/protocol.pdf?alt=media&token=014a9152-50e1-4e8b-9aaa-378b50d2f783' target='_blank'>ניתן לצפות בפרוטוקול המחקר כאן</a>
                </div>
            </div>
        </div>
    </section>
</template>

<script lang='ts'>
import { defineComponent } from '@vue/runtime-core';
import store from '@/store';

export default defineComponent({
    beforeRouteEnter(to, from, next){
        if(store.state.firebase?.user)
            next('/admin');
        else
            next();
    }
});
</script>

<style lang="scss" scoped>
section {
    margin-top: 0;
}

#home {
    border-radius: unset;
    width: 40vw;
    margin-bottom: 0;
    min-height: 100vh;
    justify-content: flex-start;

    & > div {
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    & h1 {
        background: $hoverB;
        font-size: 1.2rem;
        padding: 0.7rem;
        display: flex;
        justify-content: center;
        border-radius: unset;

        & > div {
            font: inherit;
            width: fit-content;
            transform: translateX(-32px);
        }
    }
}

.roundedBox {
    @include shadow;
    margin: 0 auto;
    text-align: center;
    padding: 1rem;
    font-size: 0.9em;
}

.parent {
    width: 40vw;
    margin-bottom: 4rem;
    
    & p {
        padding: 0 2rem;
        margin: 0.3rem;
        width: 100%;
    }

    & .info {
        @extend .roundedBox;
        width: 100%;
        background-color: $swatchC;
    }
    & h3 {
        @extend .roundedBox;
        background-color: $swatchA;
        margin-top: 1rem;

        & a {
            color: rgb(62, 96, 209);
            font-weight: bold;

            &:hover {
                color: rgb(29, 63, 173);
            }
        }
    }
}

.youtube {
    margin-bottom: 0.5rem;
    width: 100%;

    & iframe {
        width: 100%;
        aspect-ratio: 1.82;
    }
}

.ytp-chrome-top ytp-show-cards-title {
    display: none;
}

@media only screen and (max-width: 768px) {
    section {
        margin: 0;
        width: 100%;
    }
    .parent {
        width: 100% !important;
        margin: 0;
    }
    h1 {
        font-size: 1.5rem !important;
    }
}
</style>